<template>
  <div>
    <v-row v-if="isInsert">
      <v-col>
        <ImportXmlField @input="handleImportXml($event)" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider />
        <PurchaseDataSection v-model="purchase" />
      </v-col>
    </v-row>
    <div class="my-8" />
    <v-row>
      <v-col>
        <PurchaseProductList
          :purchaseProducts="purchase.purchase_products"
          @deleteProduct="deleteProduct($event)"
          @linkProduct="handleLinkProduct($event)"
          @store="handleStoreProduct($event)"
          @addProduct="handlePurchaseProductForm()"
          @editProduct="
            handlePurchaseProductForm({
              product: purchase.purchase_products[$event],
              index: $event,
            })
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PurchaseBillForm v-model="purchase.purchase_bills" />
        <v-btn
          text
          class="text-capitalize mt-2"
          color="primary"
          @click="handleStoreBillPayable()"
        >
          Cadastrar contas
        </v-btn>
      </v-col>
      <v-col>
        <PurchaseSummary v-model="purchase" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right">
        <v-btn
          @click="$router.go(-1)"
          class="text-capitalize mr-2"
          text
          color="secondary"
        >
          Voltar
        </v-btn>
        <v-btn
          @click="handleSave('draft')"
          class="text-capitalize mr-2"
          color="primary"
          outlined
        >
          Salvar Rascunho
        </v-btn>
        <v-btn
          @click="handleSave('purchased')"
          class="text-capitalize mr-2"
          color="primary"
        >
          {{ isInsert ? "Salvar" : "Confirmar" }}
        </v-btn>
      </v-col>
    </v-row>
    <PurchaseProductLink
      ref="PurchaseProductLink"
      @input="productStored($event)"
    />
    <PurchaseProductForm
      ref="PurchaseProductForm"
      @store="addProduct($event)"
      @update="updateProduct($event)"
    />
    <StoreProductForm ref="StoreProductForm" @store="productStored($event)" />
    <InsertBillPayableForm ref="InsertBillPayableForm" />
  </div>
</template>

<script>
import InsertBillPayableForm from "@/components/bill-payable/forms/InsertBillPayableForm";
import PurchaseProductForm from "@/components/purchase/form/PurchaseProductForm";
import PurchaseBillForm from "@/components/purchase/form/PurchaseBillForm";
import PurchaseProductList from "@/components/purchase/list/PurchaseProductList";
import PurchaseSummary from "@/components/purchase/ui/PurchaseSummary";
import PurchaseDataSection from "@/components/purchase/section/PurchaseDataSection.vue";
import ImportXmlField from "@/components/purchase/ui/ImportXmlField";
import PurchaseProductLink from "@/components/purchase/ui/PurchaseProductLink";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import StoreProductForm from "@/components/product/forms/StoreProductForm.vue";

export default {
  components: {
    InsertBillPayableForm,
    PurchaseProductForm,
    PurchaseBillForm,
    PurchaseProductList,
    PurchaseSummary,
    PurchaseDataSection,
    ImportXmlField,
    PurchaseProductLink,
    SupplierSearch,
    StoreProductForm,
  },

  data() {
    return {
      purchase: {
        purchase_products: [],
        purchase_bills: [],
      },

      editProductIndex: null,
    };
  },

  computed: {
    isInsert() {
      return !this.$route.params.id;
    },
    hasProducts() {
      return this.purchase.purchase_products.length > 0;
    },
  },

  created() {
    this.showPurchase();
  },

  methods: {
    handleImportXml(data) {
      this.purchase = data;
    },

    handlePurchaseProductForm(data) {
      this.$refs.PurchaseProductForm.open(data);
    },

    addProduct(product) {
      this.purchase.purchase_products.push(product);
    },

    updateProduct(data) {
      this.$set(this.purchase.purchase_products, data.index, data.product);
    },

    deleteProduct(productIndex) {
      this.$delete(this.purchase.purchase_products, productIndex);
    },

    handleLinkProduct(index) {
      this.editProductIndex = index;

      this.$refs.PurchaseProductLink.open();
    },

    handleStoreProduct(index) {
      this.editProductIndex = index;

      let purchaseProduct = JSON.parse(
        JSON.stringify(this.purchase.purchase_products[index])
      );
      purchaseProduct.supplier = this.purchase.supplier;

      this.$refs.StoreProductForm.open(null, purchaseProduct);
    },

    productStored(product) {
      this.purchase.purchase_products[this.editProductIndex].product = product;

      this.editProductIndex = null;
    },

    showPurchase() {
      if (!this.isInsert) {
        this.$loading.start();

        this.$http
          .show("purchase/purchase", this.$route.params.id)
          .then((response) => {
            this.purchase = response.purchase;
            this.$loading.finish();
          })
          .catch((error) => {
            this.$loading.finish();
          });
      }
    },

    handleSave(status) {
      this.$loading.start();

      if (this.isInsert) {
        this.store(status);
      } else {
        this.update(status);
      }
    },

    store(status) {
      this.purchase.status = status;
      this.$http
        .store("purchase/purchase", this.purchase)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update(status) {
      this.purchase.status = status;

      this.$http
        .update("purchase/purchase", this.purchase.id, this.purchase)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$loading.finish();
      this.$router.go(-1);
    },

    handleStoreBillPayable() {
      let data = {
        company: this.purchase.company,
        supplier: this.purchase.supplier,
        description: null,
        account_plan: {
          id: 20,
          name: "Compra de mercadorias",
        },
        cost_center: null,
        details: `Ref. Nota Fiscal ${this.purchase.number}`,
        parcels: this.purchase.purchase_bills.map((row) => {
          return {
            document: `${this.purchase.number}/${row.document}`,
            due_date: row.due_date,
            value: row.value,
          };
        }),
        status: "to_pay",
      };

      this.$refs.InsertBillPayableForm.open(data);
    },
  },
};
</script>

<style>
</style>